import {
    Center,
    Spinner,
    Stack,
    Switch
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { fetchGet } from "api/api";
import { Automation, AutomationInput, AutomationOutput, Equipment } from "types";
import useAutomationsStore, { setAutomations } from "./automationsStore";
import Muted from "components/muted";
import SingleAutomation from "./single-automation";
import useRulesStore, { RulesStore, setRules } from "./rulesStore";
import InputLabel from "components/input-label";
import { Device } from "../device/device";

type AutomationPilar = 'filtration' | 'ventilation' | 'humidity' | 'temperature'

const getInputByPilar = (pilar: AutomationPilar) => (automation: Automation) =>
    automation.inputs.find(x =>
        automation.rules.filter(x => x[pilar]).some(y => y.inputs.includes(x.id))
    )

export const useSkipFirstRender = (fn: () => void, deps: any[]) => {
    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        fn()
    }, [...deps])
}

export function DwellingAutomations({ dwellingId, devices = null }) {
    const [loading, setLoading] = useState(true)
    const [ids, setIds] = useState<any[] | null>(null)
    const [equipment, setEquipment] = useState<Equipment[]>([])
    const [showDisabled, setShowDisabled] = useState(false)
    const automations = useAutomationsStore()
    const rules = useRulesStore()
    function toggleDisabled(e) {
        setShowDisabled(e.target.checked)
    }


    const getAutomations = async () => {
        if (!ids) return console.warn('[getAutomations] No automation ids')

        try {
            const r = await Promise.all(ids.map(x =>
                fetchGet(`${process.env.REACT_APP_SERVER_URL}/automation/${x}`)))

            setAutomations(r.reduce((prev, curr, index) => ({
                ...prev,
                [ids[index]]: {
                    ...curr,
                    id: ids[index],
                },
            }), {}))
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!dwellingId) return
        if (Object.keys(automations).length) return

        const get = async () => {
            try {
                const { automations } = await fetchGet(`${process.env.REACT_APP_SERVER_URL}/automations?dwelling_id=${dwellingId}&include_disabled=true`)
                setIds(automations.map(x => x.id))
                if (!Boolean(automations.length)) setLoading(false)
            } catch (e) {
                console.error(e)
            }
        }

        get()
    }, [dwellingId])

    useEffect(() => {
        getAutomations()

        /**
         * Poll for automations.
         * Need this because outputs can change in the background,
         * we want to make sure we have up-to-date outputs
         * 
         * TODO: only fetch one automation at a time when
         * that automation is updated
         */
        // to.current = setInterval(getAutomations, pollTime)
        // return () => clearInterval(to.current)
    }, [ids?.length])

    useEffect(() => {
        if (!Object.keys(automations).length) return
        if (equipment.length) return
        const get = async () => {
            try {
                for (const x in automations) {
                    const r = await Promise.all(automations[x].outputs.map(x => {
                        if (x.type === 'slack') return []
                        return fetchGet(`${process.env.REACT_APP_SERVER_URL}/equipment/${x.equipment_id}`)
                    }))
                    setEquipment(prev => [...prev, ...r])
                }
            } catch (e) {
                console.error(e)
            }
        }

        get()
    }, [automations])

    useEffect(() => {
        if (!Object.keys(automations)?.length) return
        const inputsByTrigger: (x: AutomationInput[]) => (inputIds: number[]) =>
            AutomationInput[] = inputs => inputIds => {
                return inputs.filter(x => x.type !== 'schedule').filter(input => {
                    return inputIds.includes(input.id)
                })
            }

        const schedulesByTrigger: (x: AutomationInput[]) => (inputIds: number[]) =>
            AutomationInput[] = inputs => inputIds => {
                return inputs.filter(x => x.type === 'schedule').filter(input => {
                    return inputIds.includes(input.id)
                })
            }

        const outputsByTrigger: (x: AutomationOutput[]) => (outputIds: number[]) =>
            AutomationOutput[] = outputs => outputIds => {
                return outputs.filter(output => {
                    return outputIds.includes(output.id)
                })
            }

        const equipmentByOutputs: (x: AutomationOutput[]) => Equipment[] = outputs => {
            return outputs.reduce((prev, curr) => {
                if (prev.some(x => x.id === curr.equipment_id)) return prev // dedupe

                const _equipment = equipment.find(x => x.id === curr.equipment_id)
                return [
                    ...prev,
                    ...(_equipment ? [_equipment] : [])
                ]
            }, [] as Equipment[])
        }

        const format: () => RulesStore = () =>
            Object.values(automations).reduce(
                (prev, automation) => {
                    return {
                        ...prev,
                        [automation.id]: automation.rules.map(trigger => {
                            const inputs = inputsByTrigger(automation.inputs)(trigger.inputs)
                            const schedules = schedulesByTrigger(automation.inputs)(trigger.inputs)
                            const outputs = outputsByTrigger(automation.outputs)(trigger.outputs)
                            const equipment = equipmentByOutputs(outputs)

                            return {
                                inputs,
                                schedules,
                                trigger,
                                outputs,
                                equipment
                            }
                        }),
                    }
                }, {} as RulesStore)

        setRules(format())
    }, [equipment.length, automations])

    useEffect(() => {
        if (!Object.keys(rules)?.length) { return; }
        setLoading(false)
    }, [Object.keys(rules).length])

    if (loading) { return <Center><Spinner /></Center> }
    if (!Object.keys(automations).length) return <Muted>No automations</Muted>
    return (
        <Stack
            spacing={12}
        >

            <Switch defaultChecked={false}
                onChange={toggleDisabled}>Show Disabled Automations</Switch>
            {Object.keys(automations)
                // sort enabled on top
                .sort((a, b) => automations[a].enabled === automations[b].enabled ? 0 : automations[a].enabled ? -1 : 1)
                .map(key => {
                    if (showDisabled || automations[key].enabled) {
                        return (
                            < SingleAutomation
                                key={key}
                                automation={automations[key]}
                                rules={rules[key]}
                                devices={devices}
                                equipmentDetails={equipment}
                                refetch={getAutomations} />)
                    }
                })}
        </Stack>
    )
}

export {
    getInputByPilar,
}
